import React from "react";
import Pterodactyl from "../../Assets/Images/Branding/Pteradactyl2.svg";
import Speaker_Frame from "../../Assets/Images/Branding/Speaker_Frame.png";

import "./HeaderWithPterodactyl.scss";

const HeaderWithPterodactyl = () => {
  return (
    <div className="header-with-pterodactyl">
      <div className="pterodactyl">
        <img src={Pterodactyl} alt="Pterodactyl" />
      </div>
      <div className="wood-banner">
        <img src={Speaker_Frame} alt="About" className="banner-img" />
      </div>
    </div>
  );
};

export default HeaderWithPterodactyl;
