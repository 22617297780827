import "./Navbar.scss";

import { useState } from "react";
import hacklyticslogo from "../../Assets/Images/Branding/hacklytics2025.png";

const Navbar = ({ isFixed }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showBG, setShowBG] = useState(false);

  window.addEventListener("resize", (e) => {
    setScreenWidth(e.target.innerWidth);
  });

  window.addEventListener("scroll", (e) => {
    let scroll = window.scrollY;
    if (scroll > 100) {
      setShowBG(true);
    } else {
      setShowBG(false);
    }
  });

  // mobile
  const handleClick = (e) => {
    document.getElementById("hamburger-checkbox").click();
  };
  const handleCheck = (e) => {
    if (e.target.checked) {
      // mobile nav menu is now open
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      // mobile nav menu is now closed
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  if (screenWidth >= 1200) {
    // desktop navbar
    return (
      <div
        id="navbar"
        className={`${showBG ? "_navbar-bg" : ""} ${
          isFixed ? "_navbar-fixed" : "_navbar-start"
        }`}
      >
        <div className="navbar-wrapper">
          <a className="navbar-logo" href="#">
            <img src={hacklyticslogo} alt="Hacklytics logo" />
          </a>
          <div className="navbar-flex">
            <a className="nav-menuitem" href="#about">
              About
            </a>
            <a className="nav-menuitem" href="#schedule">
              Schedule
            </a>
            <a className="nav-menuitem" href="#tracks">
              Tracks
            </a>
            <a className="nav-menuitem" href="#prizes">
              Prizes
            </a>
            <a className="nav-menuitem" href="#faq">
              FAQs
            </a>
            <a className="nav-menuitem" href="#speakers">
              Speakers
            </a>
            <a className="nav-menuitem" href="#sponsors">
              Sponsors
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    // mobile navbar
    return (
      <div
        id="mobile-navbar"
        className={`${showBG ? "_mobile-navbar-bg" : ""} ${
          isFixed ? "_navbar-fixed" : "_navbar-start"
        }`}
      >
        <input
          id="hamburger-checkbox"
          type="checkbox"
          onChange={handleCheck}
        ></input>
        <label htmlFor="hamburger-checkbox" className="nav-hamburger">
          <div className="hamburger-line hamburger-top"></div>
          <div className="hamburger-line hamburger-middle"></div>
          <div className="hamburger-line hamburger-bottom"></div>
        </label>
        <div className="navbar-flex">
          <h1 className="major">Hacklytics 2025</h1>
        </div>

        <div className="mobile-navbar-clickoff" onClick={handleClick}></div>
        <div className="mobile-navbar-menu">
          <div className="nav-menu-flex">
            <a className="nav-menuitem" onClick={handleClick} href="#">
              Home
            </a>
            <a className="nav-menuitem" onClick={handleClick} href="#about">
              About
            </a>
            <a className="nav-menuitem" onClick={handleClick} href="#schedule">
              Schedule
            </a>
            <a className="nav-menuitem" onClick={handleClick} href="#tracks">
              Tracks
            </a>
            <a className="nav-menuitem" onClick={handleClick} href="#prizes">
              Prizes
            </a>
            <a className="nav-menuitem" onClick={handleClick} href="#faq">
              FAQs
            </a>
            <a className="nav-menuitem" onClick={handleClick} href="#speakers">
              Speakers
            </a>
            <a className="nav-menuitem" onClick={handleClick} href="#team">
              Team
            </a>
            <a className="nav-menuitem" onClick={handleClick} href="#sponsors">
              Sponsors
            </a>
          </div>
        </div>
      </div>
    );
  }
};

export default Navbar;
