import "./Home.css";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Section from "../../Components/Section";

import footprints_trail from "../../Assets/Images/Branding/Footprint Trail.png";
import dinosaur_fossil from "../../Assets/Images/Branding/Dinosaur Fossil.png";
import firstPlace from "../../Assets/Images/Branding/1stPlace.png";
import secondPlace from "../../Assets/Images/Branding/2ndPlace.png";
import thirdPlace from "../../Assets/Images/Branding/3rdPlace.png";

const PrizePage = () => {
  return (
    <Section id="prizes">
      <div className="prizes-flex">
        <img
          src={dinosaur_fossil}
          alt="Dinosaur Fossil"
          className="dinosaur-fossil"
        />
        <h2 className="major">Prizes</h2>
        <img
          src={footprints_trail}
          alt="Footprints Trail"
          className="footprint-trail"
        />
        <img
          src={firstPlace}
          alt="1st Place"
          className="prize-image first-place"
        />
        <div className="prize-details first-place-details">
          <p>$1000 cash or equivalent in electronics/voucher per team member</p>
        </div>
        <img
          src={secondPlace}
          alt="2nd Place"
          className="prize-image second-place"
        />
        <div className="prize-details second-place-details">
          <p>$625 cash or equivalent in electronics/voucher per team member</p>
        </div>
        <img
          src={thirdPlace}
          alt="3rd Place"
          className="prize-image third-place"
        />
        <div className="prize-details third-place-details">
          <p>$400 cash or equivalent in electronics/voucher per team member</p>
        </div>
      </div>
      <a className="continue-reading" href="#faq">
        <span className="text">FAQ</span>
        <span className="arrow">↓</span>
      </a>
    </Section>
  );
};

export default PrizePage;
