import "./Home.css";
import React from "react";
import Section from "../../Components/Section";
import SpeakerCard from "../../Components/SpeakerCard";
import HeaderWithPteradactyl from "./HeaderWithPterodactyl";
import krishi from "../../Assets/Headshots/IMG-5009-krishi.png";
import vicente from "../../Assets/Headshots/IMG-5010-vicente.png";
import emma from "../../Assets/Headshots/IMG-5013-emma.png";
import archie from "../../Assets/Headshots/IMG-5014-archie.png";
import shreiyas from "../../Assets/Headshots/Shreiyas.png";
import john from "../../Assets/Headshots/IMG-5017-john.png";
import srijan from "../../Assets/Headshots/headshot-jan-2020.png";
import jake from "../../Assets/Headshots/jake.png";
import yasin from "../../Assets/Headshots/yasin.jpeg";
import brandon from "../../Assets/Headshots/brandon.png";
import rahul from "../../Assets/Headshots/rahul.png";
import xiquan from "../../Assets/Headshots/xiquan_THD.JPG";
import leo from "../../Assets/Headshots/leo.jpg";
import alfredo from "../../Assets/Headshots/alfredo.jpeg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SpeakerPage = () => {
  return (
    <Section id="speakers" Coordinates="False">
      <div className="speakers-flex">
        <HeaderWithPteradactyl />
        <div className="tri-flex">
          <SpeakerCard
            identifier="srijan"
            img={srijan}
            speaker="Dr. Srijan Kumar"
            link="https://www.linkedin.com/in/srijankr/"
          >
            Srijan Kumar is an Assistant Professor at the College of Computing
            at the Georgia Institute of Technology. He completed his
            postdoctoral training at Stanford University, received a Ph.D. and
            M.S. in Computer Science from the University of Maryland, College
            Park, and B.Tech. from the Indian Institute of Technology,
            Kharagpur. He develops AI, ML, and Data Mining methods to detect and
            mitigate the pressing threats posed by malicious actors (e.g.,
            evaders, sockpuppets, etc.) and harmful content (e.g.,
            misinformation, hate speech etc.) to web users and platforms.
          </SpeakerCard>
          <SpeakerCard
            identifier="jacob"
            img={jake}
            speaker="Dr. Jacob Abernethy"
            link="https://www.linkedin.com/in/jacob-abernethy-5949533/"
          >
            Prof. Abernethy is Assistant Professor in the{" "}
            <a
              className="link"
              rel="noreferrer"
              href="https://www.scs.gatech.edu/"
              target="_blank"
            >
              School of Computer Science
            </a>{" "}
            in the College of Computing at the Georgia Institute of Technology.
            His research focus is Machine Learning, and he likes discovering
            connections between Optimization, Statistics, and Economics. He
            completed his PhD at{" "}
            <a href="https://www.berkeley.edu/" target="_blank">
              UC Berkeley
            </a>{" "}
            with Peter Bartlett in 2011, and was a{" "}
            <a href="https://www.simonsfoundation.org/" target="_blank">
              Simons postdoctoral fellow
            </a>{" "}
            with Michael Kearns for the following two years.
          </SpeakerCard>
          <SpeakerCard
            identifier="yasin"
            img={yasin}
            speaker="Yasin Ehsan"
            link="https://www.linkedin.com/in/yasinehsan/"
          >
            Yasin is a 13x major hackathon winner getting 1st place @ Hack
            Cornell, Hack NYU, Berkeley AI Hacks, JP Morgan Code for Good, and
            IBM Call for Code. Yasin was a Former Capital One Senior SWE and
            Product Manager, led projects increasing revenue by $200M+, designed
            two patented AWS optimizations. Yasin will talk about how to come up
            with a great idea, how to maximize teams, how to craft a 2-min
            pitch, while providing real-life examples and making every level of
            hacker feel included and something to look forward to.
          </SpeakerCard>
          <SpeakerCard
            identifier="brandon"
            img={brandon}
            speaker="Brandon Barbello"
            link="https://www.linkedin.com/in/brandon-barbello-1083b61a/"
          >
            Brandon Barbello is the Founder and COO of Archetype AI, which is
            building a next generation foundation model for understanding real
            world sensor data. Developers can use Archetype's APIs and tools to
            rapidly build Physical AI applications. He has 12+ years of
            experience as a senior product leader working on AI, software, and
            hardware products at startups and Google. He has shipped over 20
            products to positive reviews from users and the press worldwide.
          </SpeakerCard>

          <SpeakerCard
            identifier="rahul"
            img={rahul}
            speaker="Rahul Nair"
            link="https://www.linkedin.com/in/rahulunair/"
          >
            Rahul is a Machine Learning Architect at Intel with 10 years of
            experience in data science, Machine Learning, and applied Deep
            Learning. He joined Intel after completing his Master’s degree and
            has contributed to the OpenStack security group. Rahul has expertise
            in developing Deep Learning and Deep Reinforcement Learning
            algorithms optimized for Intel CPUs. He has worked on scaling Deep
            Learning pipelines on the cloud, collaborated with startups and
            OEMs, and optimized AI workloads on Intel hardware.
          </SpeakerCard>

          <SpeakerCard
            identifier="xiquan"
            img={xiquan}
            speaker="Dr. Xiquan Cui"
            link="https://www.linkedin.com/in/xiquan-cui-%E5%B4%94%E5%96%9C%E6%B3%89-2486b115/"
          >
            Dr. Xiquan Cui serves as the Head of Recommendation and
            Personalization Data Science at homedepot.com, where he leads an
            elite team of data scientists focused on developing state-of-the-art
            AI solutions to solve complex problems in e-commerce and create
            world-class digital customer experiences. Xiquan’s research
            interests include recommender system, information retrieval, and
            artificial intelligence.
          </SpeakerCard>
          <SpeakerCard
            identifier="leo"
            img={leo}
            speaker="Leo Howell"
            link="https://www.linkedin.com/in/leohowell/"
          >
            Leo Howell, a visionary IT leader with over two decades of
            experience, currently serves as the interim vice president for IT
            and chief information officer at Georgia Tech. In this role, he
            provides vision, leadership, and oversight in the development and
            implementation of information technology. He is also responsible for
            establishing the strategic direction of how the university handles
            its information technology resources. He formerly served as the
            Institute's chief information security officer (CISO).
          </SpeakerCard>
          <SpeakerCard
            identifier="alfredo"
            img={alfredo}
            speaker="Alfredo Deza"
            link="https://www.linkedin.com/in/alfredodeza/"
          >
            Alfredo Deza is a software engineer, speaker, author, and former
            Olympic athlete working as a Cloud Advocate for Microsoft. He has
            written several books about programming languages and artificial
            intelligence, and has created online courses about the cloud and
            machine learning. He currently is an Adjunct Professor at Duke
            University, and as part of his role, works closely with universities
            around the world like Georgia Tech, Duke University, and Carnegie
            Mellon, where he often gives guest lectures about technology.
          </SpeakerCard>
        </div>
      </div>
      <a className="continue-reading" href="#sponsors">
        <span className="text">Sponsors</span>
        <span className="arrow">↓</span>
      </a>
    </Section>
  );
};

export default SpeakerPage;
