import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./SpeakerCard.scss"; // Ensure you have the styles here
import "../../Pages/Home/home_page.js";

const SpeakerCard = ({ identifier, img, speaker, link, children }) => {
  return (
    <div className="speaker-card card-down">
      <div className="speaker-bg"></div>
      <LazyLoadImage
        threshold={400}
        effect="blur"
        className="speaker-img"
        src={img}
        alt={speaker}
      />
      <h1 className="speaker-major">
        <a className="link" href={link} rel="noreferrer" target="_blank">
          {speaker}
        </a>
      </h1>
      <h2 className="speaker-mini">{children}</h2>
    </div>
  );
};

export default SpeakerCard;
