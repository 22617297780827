import "./Footer.scss";
import sxlogo2 from "../../Assets/Images/Branding/sx-logo-2.png";
import dsgtlogotext from "../../Assets/Images/Branding/dsgt-logo-text.png";
import GrassBackground from "../../Assets/Images/Branding/Grass.svg";
import FooterSkull from "../../Assets/Images/Branding/footer_skull.png";
import FooterSpine from "../../Assets/Images/Branding/footer_spine.png";
import FooterShell from "../../Assets/Images/Branding/footer_shell.png";
import FooterLeaf from "../../Assets/Images/Branding/footer_leaf.png";
import FooterFish from "../../Assets/Images/Branding/footer_fish.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Footer = (params) => {
  return (
    <div id="footer">
      <div className="footer-wrapper">
        <div className="footer-section">
          <div className="footer-header">
            <h2 className="minor">
              Made with <span style={{ color: "red" }}>♥</span> by the DSGT Tech
              team.
            </h2>
            <img
              src={FooterShell}
              alt="Footer Shell"
              className="footer-fossil shell"
            />
          </div>
        </div>
        <div className="footer-section">
          <div className="footer-header">
            <LazyLoadImage
              threshold={400}
              effect="blur"
              className="dsgtlogo"
              src={dsgtlogotext}
              alt="dsgt"
            />
            <h1 className="major">DSGT</h1>
            <img
              src={FooterSkull}
              alt="Footer Skull"
              className="footer-fossil skull"
            />
          </div>
          <div className="footer-links">
            <a
              className="mini"
              href="https://datasciencegt.org/"
              target="_blank"
            >
              About Us
            </a>
            <a
              className="mini"
              href="https://www.instagram.com/datasciencegt/"
              target="_blank"
            >
              Instagram
            </a>
            <a
              className="mini"
              href="https://www.linkedin.com/company/dsgt/"
              target="_blank"
            >
              LinkedIn
            </a>
            <a
              className="mini"
              href="https://github.com/DataScience-GT"
              target="_blank"
            >
              Github
            </a>
            <a
              className="mini"
              href="mailto:info@hacklytics.io"
              target="_blank"
            >
              Contact
            </a>
          </div>
        </div>
        <div className="footer-section">
          <div className="footer-header">
            <h1 className="major">Hacklytics</h1>
            <img
              src={FooterSpine}
              alt="Footer Spine"
              className="footer-fossil spine"
            />
          </div>
          <div className="footer-links">
            <a
              className="mini"
              href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf"
            >
              MLH Code of Conduct
            </a>
            <a className="mini" href="">
              Previous Websites
            </a>
          </div>
        </div>
        <img
          src={FooterFish}
          alt="Footer Fish"
          className="footer-fossil fish"
        />
        <img
          src={FooterLeaf}
          alt="Footer Leaf"
          className="footer-fossil leaf"
        />
      </div>
    </div>
  );
};

export default Footer;
