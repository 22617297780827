import Home from './Pages/Home';
import './App.css';
import { useLocation } from "react-router";
import { useEffect, useCallback } from "react";
import trackPathForAnalytics from './TrackPathForAnalytics';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC-_egrUkZ6aYda4QK0kqgS5rejWGF4JzU",
  authDomain: "hacklytics2024.firebaseapp.com",
  projectId: "hacklytics2024",
  storageBucket: "hacklytics2024.appspot.com",
  messagingSenderId: "370664911802",
  appId: "1:370664911802:web:cb7fbe2224e185e52fa383",
  measurementId: "G-FMTZMTCGND"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  useEffect(() => {
    logEvent(analytics, "test_firebase_analytics");
  }, []);

  return (
    <div className='App'>
      <Home />
    </div>
  );
}

export default App;
