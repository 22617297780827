import React from "react";
import footprints_duo from "../../../Assets/Images/Branding/footprints_duo.png";
import "./FootPrintsFour.scss";

const FootPrintsFour = ({ count }) => {
  return (
    <div className="footprints-animation-four">
      {[...Array(count)].map((_, index) => (
        <img
          key={index}
          src={footprints_duo}
          alt="Footprints"
          className={`footprint footprint-${index + 1}`}
        />
      ))}
    </div>
  );
};

export default FootPrintsFour;
