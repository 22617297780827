import "./TrackCard.scss";
import vector from "../../Assets/Images/Branding/Vector.png";

const TrackCard = (params) => {
  return (
    <div className="track-card">
      <input
        className="card-check"
        id={"track-card-" + params.identifier}
        type="checkbox"
      />
      <label
        htmlFor={"track-card-" + params.identifier}
        className="track-card-inner"
      >
        <div className="track-card-front">
          <div className="front-style">
            <h1 className="major-card">{params.track}</h1>
            <img src={vector} alt="vector-pic" className="vector-img" />
            <h2 className="major-card-sub">{params.subtext}</h2>
          </div>
        </div>

        <div className="track-card-back">
          <p>{params.children}</p>
        </div>
      </label>
    </div>
  );
};

export default TrackCard;
