import './Section.scss';
import React, { useEffect } from 'react';
import Coordinates from "../../Components/Coordinates";

const Section = (params) => {
    return (
        <div className='section' id={params.id}>
            {params.Coordinates === "True" && (
                <div>
                    <Coordinates ScrollDirection="Right"/>
                </div>
            )}
            <div className='section-wrapper'>{params.children}</div>
            {params.Coordinates === "True" && (
                <div>
                    <Coordinates ScrollDirection="Left"/>
                </div>
            )}
        </div>
    )
}

export default Section;