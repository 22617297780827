import "./Faq.scss";
import airplane from "../../Assets/Images/Other/airplane.png";
import ArrowSign from "../../Assets/Images/Branding/Arrow Sign.png";

const Faq = (params) => {
  const identifier = "faq-checkbox-" + params.identifier;

  return (
    <div className="faq">
      <input id={identifier} className="faq-checkbox" type="checkbox" />
      <label className="faq-label" htmlFor={identifier}>
        <span className="faq-question">{params.question}</span>
        <span className="faq-arrow">↓</span>
        {params.identifier === "b" && (
          <img className="arrow-sign" src={ArrowSign} alt="Arrow Sign" />
        )}
      </label>
      <p className="faq-answer">{params.children}</p>
    </div>
  );
};

export default Faq;
